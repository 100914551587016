import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CaptureFormService} from "../shared/services/capture-form.service";
import {MatDialog} from "@angular/material/dialog";
import {DialogFormComponent} from "../dialog-form/dialog-form.component";
import {ActivatedRoute} from '@angular/router';
import {Title} from "@angular/platform-browser";
import {WebForm} from "../shared/models/web_form.model";
import {WebFormField} from "../shared/models/web_form_field.model";

@Component({
    selector: 'app-capture-form',
    templateUrl: './capture-form.component.html',
    styleUrls: ['./capture-form.component.scss']
})
export class CaptureFormComponent implements OnInit {
    formGroup: FormGroup = new FormGroup({});
    marketCd: string;
    event: string;
    webForm: WebForm = new WebForm();
    dbForm: any = {}
    route: string;
    isLoading: boolean = true;
    multiBtnList: any[] = []

    constructor(private captureFormService: CaptureFormService,
                public dialog: MatDialog, 
                private activatedRoute: ActivatedRoute,
                private titleService: Title) {
    }

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe(params => {
            this.marketCd = params.get('marketCd');
            this.event = params.get('event');
        })
        this.route = `${this.marketCd}${this.event != null ? '/' + this.event : ''}`;

        this.captureFormService.get()
        .subscribe((r) => {
            let api_form = {}
             r.forEach(element => {
                api_form[element.url] = element;
                
            });
            this.dbForm = api_form[this.route]
            this.dbForm.webFormFieldList.map(field => {
                let validators = []
                field.required && validators.push(Validators.required)
                field.regexValidation && validators.push(Validators.pattern(field.regexValidation))
                this.formGroup.addControl(
                    field.field,
                    new FormControl('', validators)
                )
            })
            this.formGroup.addControl(
                "optIn",
                new FormControl('')
            )

            this.multiBtnList = this.dbForm.webFormFieldList.filter(k => k.type == 'multi_btn');
            this.multiBtnList.map(config => config.defaultValue = Object.entries(JSON.parse(config.defaultValue)));

            this.dbForm.webFormFieldList = this.dbForm.webFormFieldList
                                                      .filter(k => k.type != 'multi_btn')
                                                      .sort((a, b) => a.id - b.id);

            document.documentElement.style.setProperty('--primary-color', this.dbForm.primaryColor);
            this.webForm.url = this.dbForm.url;
            this.isLoading = false;
            this.titleService.setTitle(this.dbForm.pageTitle);
            if (this.dbForm.faviconLocation && this.dbForm.faviconLocation != '') {
                let favicon = document.getElementById('favicon');
                if (favicon) 
                    favicon.setAttribute("href", this.dbForm.faviconLocation);
            }
         });
    }

    submit(): void {
        let optIn: boolean;
        console.log(Object.entries(this.formGroup.value));
        const webFormFields: Array<WebFormField> = Object.entries(this.formGroup.value)
                                    .map(([name, value]: [string, any]) => {
                                        if (name == "optIn") {
                                            optIn = value;
                                        }
                                        return new WebFormField(name, value);
                                    })
                                    .filter(webFormField => webFormField.name != "optIn"
                                        && webFormField.value != null && webFormField.value !== '')
        this.webForm.fields = webFormFields;
        this.webForm.emailOptIn = optIn;
        this.webForm.phoneOptIn = optIn;

        this.captureFormService.newRegister(this.webForm).subscribe({
            next: (response) => {
                this.dialog.open(DialogFormComponent, {
                    data: {
                        status: 'Success',
                        message: 'Profile saved successfully!'
                    }
                });
                this.formGroup.reset();
                for (let name in this.formGroup.controls) {
                    this.formGroup.controls[name].setErrors(null);
                }
            },
            error: (e) => {
                this.dialog.open(DialogFormComponent, {
                    data: {
                        status: 'Error',
                        message: 'Error saving profile. Please review the information provided and try again.'
                    }
                });
            }
        });
    }
}
