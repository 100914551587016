export class WebFormField{
    name: string;
    value?: string | null;

    constructor(name: string, value: string) {
        this.name = name;
        this.value = value;
    }

}
