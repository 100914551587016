<form [formGroup]="form" (ngSubmit)="submit()" class="form-box">
    <div class="form-title">
        <h1>Register Today</h1>
    </div>
    <div class="card--row">
        <br>
        <mat-form-field appearance="outline">
            <mat-label>First Name</mat-label>
            <input matInput formControlName="firstName" maxlength="100" required>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="lastName" maxlength="100" required>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Email Address</mat-label>
            <input matInput formControlName="email" maxlength="500" required>
            <mat-error *ngIf="form.get('email').hasError('pattern')">Not a valid email</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>ZIP Code</mat-label>
            <input matInput formControlName="postalCd" maxlength="5" OnlyNumber="true">
            <mat-error *ngIf="form.get('postalCd').hasError('pattern')">Not a valid ZIP code</mat-error>
        </mat-form-field>
        <mat-checkbox style="color:#616161" color="primary" formControlName="emailOptIn">
            By entering my email and clicking Register, I agree to hear from The Cosmetics Company Store, the <a href="https://www.thecosmeticscompanystore.com/en-us/about-us/terms-and-conditions">Terms & Conditions</a>, and, if I am a California resident, the <a href="https://www.thecosmeticscompanystore.com/en-us/about-us/ca-do-not-sell">Notice of Financial Incentive</a>. To learn more, view our <a href="https://www.thecosmeticscompanystore.com/en-us/about-us/privacy-policy">Privacy Policy</a>.
        </mat-checkbox>
    </div>
    <button mat-raised-button type="submit" class="button--submit" [disabled]="form.invalid"><span style="margin:0;color:white">Register</span></button>
    <footer>
        <span>The Cosmetics Company Store</span>
        <span><a href="https://www.thecosmeticscompanystore.com">thecosmeticscompanystore.com</a></span>
        <span>Beauty Pro Community</span>
        <span>80 Ruland Road, Melville New York 11747</span>
        <span>1-800-383-5243</span>
        <span><a href="mailto:thecosmeticscompanystore@gcc.estee.com">thecosmeticscompanystore@gcc.estee.com</a></span>
    </footer>
</form>
