import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { TopBarComponent } from './top-bar/top-bar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {RouterModule} from "@angular/router";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import { DialogFormComponent } from './dialog-form/dialog-form.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatSelectModule} from "@angular/material/select";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {CaptureFormCanadaComponent} from "./capture-form-canada/capture-form-canada.component";
import {CaptureFormComponent} from "./capture-form/capture-form.component";
import { CaptureFormUsComponent } from './capture-form-us/capture-form-us.component';
import {OnlyNumber} from './capture-form-us/onlynumber.directive';
import { CaptureFormNashvilleComponent } from './capture-form-nashville/capture-form-nashville.component';

@NgModule({
    declarations: [
        AppComponent,
        TopBarComponent,
        CaptureFormCanadaComponent,
        CaptureFormComponent,
        DialogFormComponent,
        CaptureFormUsComponent,
        OnlyNumber,
        CaptureFormNashvilleComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        RouterModule.forRoot([
            {path: '', redirectTo: 'us', pathMatch:'full'},
            {path: 'old-ca', component: CaptureFormCanadaComponent},
            {path: ':marketCd', component: CaptureFormComponent},
            {path: ':marketCd/:event', component: CaptureFormComponent},
            {path: 'old-us', component: CaptureFormUsComponent},
            {path: 'old-us/store', component: CaptureFormNashvilleComponent}
        ], { useHash: true}),
        MatFormFieldModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        MatSelectModule,
        MatButtonToggleModule
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
