<form [formGroup]="form" (ngSubmit)="submit()" class="form-box">
    <div class="form-title">
        <h1>Register Today</h1>
    </div>
    <div class="card--row">
        <br>
        <mat-form-field appearance="outline">
            <mat-label>First Name</mat-label>
            <input matInput formControlName="firstName" maxlength="100" required>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="lastName" maxlength="100" required>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Email Address</mat-label>
            <input matInput formControlName="email" maxlength="500" required>
            <mat-error *ngIf="form.get('email').hasError('pattern')">Not a valid email</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Postal Code</mat-label>
            <input matInput formControlName="postalCd" maxlength="7">
            <mat-error *ngIf="form.get('postalCd').hasError('pattern')">Not a valid postal code</mat-error>
        </mat-form-field>
        <p>
            Preferred Language
            <mat-button-toggle-group appearance="standard" formControlName="language" aria-label="Preferred Language">
                <mat-button-toggle value="en">English</mat-button-toggle>
                <mat-button-toggle value="fr">French</mat-button-toggle>
            </mat-button-toggle-group>
        </p>
        <mat-checkbox style="color:#616161" color="primary" formControlName="emailOptIn">Sign me up to hear from The Cosmetics Warehouse
            about future Canadian warehouse sale events. You can unsubscribe at any time. <a href="https://www.thecosmeticscompanystore.com/en-us/about-us/terms-and-conditions">Terms & Conditions</a>
        </mat-checkbox>
    </div>
    <button mat-raised-button type="submit" class="button--submit" [disabled]="form.invalid"><span style="margin:0;color:white">Register</span></button>
    <footer>
        <span>The Cosmetics Company Store</span>
        <span><a href="mailto:thecosmeticswarehouse@estee.ca">thecosmeticswarehouse@estee.ca</a></span>
        <span>351 Hillmount Road, Markham Ontario L6C 2S3, Canada - 289-800-5484</span>
    </footer>
</form>
