import {WebFormField} from "./web_form_field.model";

export class WebForm {
    url: string;
    fields: WebFormField[];
    emailOptIn: boolean;
    phoneOptIn: boolean;

     constructor(webForm?: WebForm) {
         this.url = webForm?.url;
         this.fields = webForm?.fields;
     }
}