/**
 * Status code used on api
 */
import {Address} from "./address.model";

export enum ContactChannelStatus {
    CONTACTABLE = 'C',
    NOT_CONTACTABLE = 'N',
    UNKNOWN = 'U'
}

export class Profile {
    firstName?: string | null;
    lastName?: string | null;
    emailAddr?: string | null;
    addr?: Address | null;
    language?: string | null;
    emailOptInCd?: ContactChannelStatus;
    regionCd?: string | null;
    marketCd?: string | null;
    extlLocationId?: string | null;

    constructor(profile?: Profile) {
        this.firstName = profile?.firstName;
        this.lastName = profile?.lastName;
        this.emailAddr = profile?.emailAddr;
        this.addr = profile?.addr;
        this.language = profile?.language;
        this.emailOptInCd = profile?.emailOptInCd;
        this.regionCd = profile?.regionCd;
        this.marketCd = profile?.marketCd;
        this.extlLocationId = profile?.extlLocationId;
    }
}
