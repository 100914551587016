import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ContactChannelStatus, Profile} from "../shared/models/profile.model";
import {Address} from "../shared/models/address.model";
import {CaptureFormService} from "../shared/services/capture-form.service";
import {MatDialog} from "@angular/material/dialog";
import {DialogFormComponent} from "../dialog-form/dialog-form.component";

const EMAIL_PATTERN = /^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
const ZIP_PATTERN = /\d{5}$/;

@Component({
  selector: 'app-capture-form-us',
  templateUrl: './capture-form-us.component.html',
  styleUrls: ['./capture-form-us.component.scss']
})
export class CaptureFormUsComponent implements OnInit {

  /* Dialog input form */
  public form = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.pattern(EMAIL_PATTERN)]),
    postalCd: new FormControl('', [Validators.pattern(ZIP_PATTERN)]),
    language: new FormControl(''),
    emailOptIn: new FormControl(false)
  });

  profile: Profile = new Profile();
  address: Address = new Address();

  constructor(private captureFormService: CaptureFormService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  showDialog(status, message): void {
    this.dialog.open(DialogFormComponent, {
      data: {
        status: status,
        message: message
      }
    });
  }

  submit(): void {
    this.profile.firstName = this.form.get('firstName')?.value == undefined ? null : this.form.get('firstName')?.value;
    this.profile.lastName = this.form.get('lastName')?.value == undefined ? null : this.form.get('lastName')?.value;
    this.profile.emailAddr = this.form.get('email')?.value == undefined ? null : this.form.get('email')?.value;
    this.address.postalCd = this.form.get('postalCd')?.value == undefined ? null : this.form.get('postalCd')?.value;
    this.profile.emailOptInCd = this.form.get('emailOptIn')?.value == true ? ContactChannelStatus.CONTACTABLE : ContactChannelStatus.NOT_CONTACTABLE;
    this.profile.addr = this.address;
    this.profile.marketCd = 'US';
    this.profile.regionCd = 'NA';

    this.captureFormService.register(this.profile).subscribe({
      next: (response) => {
        this.dialog.open(DialogFormComponent, {
          data: {
            status: 'Success',
            message: 'Profiled saved successfully!'
          }
        });
        this.form.reset();
        for (let name in this.form.controls) {
          this.form.controls[name].setErrors(null);
        }
      },
      error: (e) => {
        console.log(e.status);
        switch (e.status) {
          case 400:
            console.log('1')
            this.showDialog('Error', 'Error saving profile. Please review the information provided and try again.');
            break;
          default:
            console.log('2')
            this.showDialog('Internal Error', 'We\'re sorry, but an unexpected error has occurred. Please try again later. If the issue persists, please don\'t hesitate to contact us for further assistance.');
            break;
        }
      }
    });
  }
}
