<form [formGroup]="form" (ngSubmit)="submit()" class="form-box">
    <div class="form-title">
        <h1>Register Today</h1>
    </div>
    <div class="card--row">
        <br>
        <mat-form-field appearance="outline">
            <mat-label>First Name</mat-label>
            <input matInput formControlName="firstName" maxlength="100" required>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="lastName" maxlength="100" required>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Email Address</mat-label>
            <input matInput formControlName="email" maxlength="500" required>
            <mat-error *ngIf="form.get('email').hasError('pattern')">Not a valid email</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>ZIP Code</mat-label>
            <input matInput formControlName="postalCd" maxlength="5" OnlyNumber="true">
            <mat-error *ngIf="form.get('postalCd').hasError('pattern')">Not a valid ZIP code</mat-error>
        </mat-form-field>
        <mat-checkbox style="color:#616161" color="primary" formControlName="emailOptIn">Sign me up to hear from The Cosmetics Warehouse
            about future Melvile, NY warehouse sale events. You can unsubscribe at any time. <a href="mailto:thecosmeticswarehouse@estee.com">Contact Us.</a>
        </mat-checkbox>
    </div>
    <button mat-raised-button type="submit" class="button--submit" [disabled]="form.invalid"><span style="margin:0;color:white">Register</span></button>
    <footer>
        <span>The Cosmetics Warehouse</span>
        <span><a href="mailto:thecosmeticswarehouse@estee.com">thecosmeticswarehouse@estee.com</a></span>
        <span>80 Ruland Road, Melville New York 11747 - 631-559-3168</span>
    </footer>
</form>
