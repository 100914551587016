import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {WebForm} from "../models/web_form.model";
import {Profile} from "../models/profile.model";
import { Observable, map } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    Authorization: `Bearer ${environment.authToken}`,
    'Client-Id': `${environment.clientId}`
  })
};

@Injectable({
  providedIn: 'root'
})
export class CaptureFormService {

  API_WEB_FORM = `${environment.apiUrl}/web-form/`;
  OLD_API = `${environment.apiUrl}/consumers/register`;
  API_REGISTER = `${environment.apiUrl}/web-form/register`


  constructor(private http: HttpClient) { }
  
  get(): Observable<any[]>{
    return this.http.get<any>(this.API_WEB_FORM,httpOptions).pipe(map(response=>response.payload))
  }

  register(profile: Profile) {
    return this.http.post(
        `${this.API_REGISTER}`,
        profile,
        httpOptions);
  }

  newRegister(webForm: WebForm) {
    return this.http.post(
        `${this.API_REGISTER}`,
        webForm,
        httpOptions);
  }
}
