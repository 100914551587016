import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ContactChannelStatus, Profile} from "../shared/models/profile.model";
import {CaptureFormService} from "../shared/services/capture-form.service";
import {Address} from "../shared/models/address.model";
import {MatDialog} from "@angular/material/dialog";
import {DialogFormComponent} from "../dialog-form/dialog-form.component";

const EMAIL_PATTERN = /^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
const ZIP_PATTERN = /^[a-zA-Z0-9]{3}[ \t]?[a-zA-Z0-9]{3}$/;

@Component({
    selector: 'app-capture-form-canada',
    templateUrl: './capture-form-canada.component.html',
    styleUrls: ['./capture-form-canada.component.scss']
})
export class CaptureFormCanadaComponent implements OnInit {

    /* Dialog input form */
    public form = new FormGroup({
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.pattern(EMAIL_PATTERN)]),
        postalCd: new FormControl('', [Validators.pattern(ZIP_PATTERN)]),
        language: new FormControl(''),
        emailOptIn: new FormControl(false)
    });

    profile: Profile = new Profile();
    address: Address = new Address();

    constructor(private captureFormService: CaptureFormService,
                public dialog: MatDialog) {
    }

    ngOnInit(): void {
    }

    submit(): void {
        this.profile.firstName = this.form.get('firstName')?.value == undefined ? null : this.form.get('firstName')?.value;
        this.profile.lastName = this.form.get('lastName')?.value == undefined ? null : this.form.get('lastName')?.value;
        this.profile.emailAddr = this.form.get('email')?.value == undefined ? null : this.form.get('email')?.value;
        this.address.postalCd = this.form.get('postalCd')?.value == undefined ? null : this.form.get('postalCd')?.value;
        this.address.countryCd = this.form.get('language')?.value == undefined ? null : this.form.get('language')?.value;
        this.profile.emailOptInCd = this.form.get('emailOptIn')?.value == true ? ContactChannelStatus.CONTACTABLE : ContactChannelStatus.NOT_CONTACTABLE;
        this.profile.addr = this.address;
        this.profile.marketCd = 'CA';
        this.profile.regionCd = 'NA';

        this.captureFormService.register(this.profile).subscribe({
            next: (response) => {
                this.dialog.open(DialogFormComponent, {
                    data: {
                        status: 'Success',
                        message: 'Profiled saved successfully!'
                    }
                });
                this.form.reset();
                for (let name in this.form.controls) {
                    this.form.controls[name].setErrors(null);
                }
            },
            error: (e) => {
                this.dialog.open(DialogFormComponent, {
                    data: {
                        status: 'Error',
                        message: 'Error saving profile. Please review the information provided and try again.'
                    }
                });
            }
        });
    }
}
