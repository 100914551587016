export class Address {
    line1?: string | null;
    line2?: string | null;
    line3?: string | null;
    line4?: string | null;
    city?: string | null;
    stateProvCd?: string | null;
    postalCd?: string | null;
    countryCd?: string | null;

    constructor(address?: Address) {
        this.line1 = address?.line1;
        this.line2 = address?.line2;
        this.line3 = address?.line3;
        this.line4 = address?.line4;
        this.city = address?.city;
        this.stateProvCd = address?.stateProvCd;
        this.postalCd = address?.postalCd;
        this.countryCd = address?.countryCd;
    }
}
